<template>
  <v-dialog
    :value="true"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    transition="slide-y-reverse-transition"
    min-width="1600"
    scrollable
    persistent
    @keydown.esc="goToOrdersPage"
  >
    <OrderPartForm
      v-if="veneerSuppliers && handleSuppliers"
      :order-part="editedOrderPart"
      :available-order-part-options="availableOrderPartOptions"
      :handle-suppliers="handleSuppliers"
      :veneer-suppliers="veneerSuppliers"
      :glass-suppliers="glassSuppliers"
      :paint-suppliers="paintSuppliers"
      :errors="orderPartValidationErrors"
      :disabled="$store.getters.loading[`put:api/orders/${editedOrderPart.id}`]"
      @clear:errors="clearOrderPartValidationErrors"
      @cancel="goToOrdersPage"
      @save="onSave"
    />
  </v-dialog>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import store from '@/store';
import OrderPartForm from '@/components/OrderPartForm';

export default {
  name: 'EditOrderPart',

  components: { OrderPartForm },

  computed: {
    ...mapState('orderParts', [
      'editedOrderPart',
      'orderPartValidationErrors',
      'orderPartsFilterParams',
      'availableOrderPartOptions',
    ]),
    ...mapState('orders', ['orderFilterParams']),
    ...mapGetters('suppliers', [
      'handleSuppliers',
      'veneerSuppliers',
      'glassSuppliers',
      'paintSuppliers',
    ]),
  },

  created() {
    if (!this.handleSuppliers || !this.veneerSuppliers) {
      this.fetchSuppliers();
    }
  },

  beforeRouteEnter(to, from, next) {
    store
      .dispatch('orderParts/editOrderPart', +to.params.id)
      .then(() => {
        next();
      })
      .catch(() => {
        next({ name: 'orders', query: store.state.orders.orderFilterParams });
      });
  },

  methods: {
    ...mapActions('orderParts', [
      'updateOrderPart',
      'clearOrderPartValidationErrors',
      'fetchAvailableOptions',
    ]),

    ...mapActions('suppliers', ['fetchSuppliers']),

    onSave(orderPart) {
      this.updateOrderPart(orderPart).then(() => {
        this.goToOrdersPage();
      });
    },

    goToOrdersPage() {
      this.$router.push({ name: 'orders', query: this.orderFilterParams }).catch(() => {});
    },
  },
};
</script>

<style scoped></style>
